'use strict';

Gri.module({
  name: 'map-alpha',
  ieVersion: null,
  $el: $(".map-alpha"),
  container: '.map-alpha',
  fn: function () {
    let $tabAlpha = $('.tab-alpha');
    let $refLink = $('.tab-alpha-list li a');
    let $thisList = $('.tab-alpha ul');
    let $thisSelect = $('.tab-alpha').find('select');
    let marker = [];
    let markerStyle = 'red';
    let style = [
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e9e9e9"
          },
          {
            "lightness": 17
          }
        ]
      },
      {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f5f5"
          },
          {
            "lightness": 20
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#ffffff"
          },
          {
            "lightness": 17
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#ffffff"
          },
          {
            "lightness": 29
          },
          {
            "weight": 0.2
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          },
          {
            "lightness": 18
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          },
          {
            "lightness": 16
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f5f5"
          },
          {
            "lightness": 21
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dedede"
          },
          {
            "lightness": 21
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "visibility": "on"
          },
          {
            "color": "#ffffff"
          },
          {
            "lightness": 16
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "saturation": 36
          },
          {
            "color": "#333333"
          },
          {
            "lightness": 40
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f2f2f2"
          },
          {
            "lightness": 19
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#fefefe"
          },
          {
            "lightness": 20
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#fefefe"
          },
          {
            "lightness": 17
          },
          {
            "weight": 1.2
          }
        ]
      }
    ];
    let data = referansData;
    let mapCenterResponsive = {lat: 39.179904, lng: 34.773426};


    let map = new google.maps.Map($('.map-alpha').find('#map')[0], {
      center: mapCenterResponsive,
      scrollwheel: true,
      styles: style,
      zoom: 6,
      disableDefaultUI: false
    });

    $tabAlpha.find('.tab-alpha-list a').removeClass('active');
    $tabAlpha.find('.tab-alpha-list a').eq(0).addClass('active');

    let val = $thisList.find('a.active').attr('data-category');

    for (let index in data) {
      if (data[index].coordinate != '') {
        let coordinateSplit = data[index].coordinate.split(',');
        data[index].coordinate = {
          lat: Number(coordinateSplit[0]),
          lng: Number(coordinateSplit[1])
        };
      } else {
        console.warn(data[index].category + ' kategorisinde koordinat bulunmuyor.');
      }
      createMarker(data[index], val);
    }
    
    

    $thisSelect.on('change', function () {
      var selectActive = parseInt($('.dropdown-menu li.selected').attr('data-original-index')) + 1;
      const selectVal = $(this).val();
      $('.tab-content div').removeClass('active');
      $(".tab-content #"+selectActive+"").addClass('active');
      setMapOnAll(null);
      marker = [];
      for (let index in data) {
        createMarker(data[index], selectVal);
      }
    });

    $thisList.find('a').on('click', function () {
      $thisList.find('a').removeClass('active');
      $(this).addClass('active');
      setMapOnAll(null);
      marker = [];
      for (let index in data) {
        createMarker(data[index], $(this).attr('data-category'));
      }
    });

    function setMapOnAll(map) {
      for (let i = 0; i < marker.length; i++) {
        marker[i].setMap(map);
      }
    }

    function createMarker(markerObj, valType) {
      let active = false;
      $.each($refLink, function (index, item) {
        if ($(this).hasClass('active')) {
          if (markerObj.tag === valType) {
            markerStyle = 'red';
            active = true;
          } else {
            markerStyle = 'gray';
            active = false;
          }
        }
      });
      if(markerObj.coordinate != ""){
        var markerItem = marker[marker.length] = new google.maps.Marker({
          position: markerObj.coordinate,
          map: map,
          clickable: active,
          icon: '/images/pin-' + markerStyle + '.png'
        });
        let mapCenterReset = { lat: 39.311416, lng: 34.795634 };
        map.setCenter(mapCenterReset);
        map.setZoom(6);   
        markerItem.addListener('click', function () {
          map.setZoom(15);
          map.setCenter(markerItem.getPosition());
        });


      }
    }   

    $('.select-item').selectpicker();
  }
});
